<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 125px);">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Productos',
                    ruta: 'admin.descuento.productos.lista'
                },
                {
                    titulo: 'Parámetros de descuento',
                    ruta: 'admin.descuento.parametros-descuento',
                },
                {
                    titulo: 'Clasificación de descuento',
                    ruta: 'admin.descuento.clasificacion.descuento',
                },
                {
                    titulo: 'Etiquetas de descuento',
                    ruta: 'admin.descuento.etiquetas',
                },
                {
                    titulo: 'Matriz de descuento',
                    ruta: 'admin.descuento.compras',
                },
            ]
        }
    },
}
</script>
